<template lang="pug">
modal(size='base' @close='$emit("close")')
  generic-form#FormInquiryAddressUpdate(:endpoint='`/_/AddressForm/UpdateAddress/AddressToUpdate?id=${id}&type=${context}&addressType=${type}`' @submitSuccess='$emit("close")')
    template(#default)
      form-header(@cancel='$emit("close")')
        span {{ $t(`Update Address for Inquiry:`) }} &nbsp;
        em {{ title || id }}

      form-hidden(name='AddressIdentifier')
      form-hidden(name='Type' :modelValue='type')
      form-input(name='AddressName' type='input' :modelValue='title')
      form-input(name='ContactPerson' type='input')
      form-input(name='Street' type='input')
      div(class='sm:grid sm:grid-cols-2 sm:gap-4')
        form-input(name='CityCode' type='input')
        form-input(name='City' type='input')
      form-select-native(name='Country')
      form-input(name='Phone' type='input')
      form-checkbox(class='mb-4' name='AlsoStoreAtCustomer')
      form-footer
        form-action(:primary='true' name='UpdateAddress')
          template(#inner) {{ $t('Save') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormInquiryAddressUpdate = defineComponent({
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    title: { type: String, required: true },
    context: { type: String, required: true },
  },
})
export default FormInquiryAddressUpdate
</script>
